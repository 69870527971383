import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import 'react-datepicker/dist/react-datepicker.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { callApi } from '../utills/api';
import { showError } from '../utills/sweetAlert';
import { faCartShopping, faLayerGroup } from '@fortawesome/free-solid-svg-icons';

const { RangePicker } = DatePicker;

interface CashData {
  month: number;
  amount: number;
}

interface GraphDetails {
  cashIn: CashData[];
  cashOut: CashData[];
  TotalAmountcashin: number;
  TotalAmountcashout: number;
}

const CashInCard = () => {
  const [graphDetails, setGraphDetails] = useState<GraphDetails>({
    cashIn: [],
    cashOut: [],
    TotalAmountcashin: 0,
    TotalAmountcashout: 0,
  });

  const [filterTemp, setFilterTemp] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    if (filterTemp.startDate && filterTemp.endDate) {
      let req = {
        from_date: filterTemp.startDate,
        to_date: filterTemp.endDate
      };
      callApi("POST", "dashboard_graph.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response) {
            setGraphDetails(response.data);
          }
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });
    } else {
      graphShow();
    }
  }, [filterTemp]);

  useEffect(() => {
    graphShow();
  }, []);

  const graphShow = () => {
    callApi("GET", "dashboard_graph.php", "")
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          setGraphDetails(response.data);
        }
      })
      .catch((err: any) => {
        console.error("API call error:", err.response ? err.response.data.error : err.message);
        showError("Failed", err.response ? err.response.data.error : err.message);
      });
  };

  // Helper function to convert month number to month name
  const getMonthName = (monthNumber: number) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return monthNames[monthNumber - 1];
  };

  return (
    <div className="element-container webview">
      <Row className='pb-2 dflexAcenter'>
        <Col lg="9" md="10">
          <h4 className="category-title pb-2">
            <FontAwesomeIcon icon={faLayerGroup} className="icon" /> Overview
          </h4>
        </Col>
        <Col lg="3" md="2" className="flexend">
        <RangePicker
              value={[
                filterTemp.startDate ? dayjs(filterTemp.startDate) : null,
                filterTemp.endDate ? dayjs(filterTemp.endDate) : null,
              ]}
              onChange={(dates) => {
                setFilterTemp({
                  startDate: dates?.[0] ? dates[0].format('YYYY-MM-DD') : null,
                  endDate: dates?.[1] ? dates[1].format('YYYY-MM-DD') : null,
                });
              }}
              format="DD-MM-YYYY"
              style={{ width: '100%' }}
            />
        </Col>
      </Row>
      <Row>
        <Col lg="6" md="6">
          <div className="cash-in-card">
            <div className="cash-in-header">
              <h2 style={{ color: "#006820" }}><b>Cash IN</b></h2>
              <div className="cart-icon"><FontAwesomeIcon icon={faCartShopping} /></div>
            </div>
            <p className="cash-amount">₹{graphDetails.TotalAmountcashin}</p>

            <div className="chart-container">
              <ResponsiveContainer width="100%" height={200}>
                <BarChart
                  data={graphDetails.cashIn.map((item) => ({
                    ...item,
                    month: getMonthName(item.month),
                  }))}
                  margin={{ top: 10, right: 80, left: 50, bottom: 5 }}
                >
                  <XAxis dataKey="month" tick={{ fill: '#006820', fontWeight: 'bold' }} />
                  <YAxis hide={true} />
                  <Tooltip />
                  <Bar dataKey="amount" fill="#006820" radius={[5, 5, 0, 0]} barSize={50} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </Col>
        <Col lg="6" md="6">
          <div className="cash-out-card">
            <div className="cash-in-header">
              <h2 style={{ color: "#900a0a" }}><b>Cash Out</b></h2>
              <div className="cashouticon">&#36;</div>
            </div>
            <p className="cash-amount">₹{graphDetails.TotalAmountcashout}</p>

            <div className="chart-container">
              <ResponsiveContainer width="100%" height={200}>
                <BarChart
                  data={graphDetails.cashOut.map((item) => ({
                    ...item,
                    month: getMonthName(item.month),
                  }))}
                  margin={{ top: 10, right: 80, left: 50, bottom: 5 }}
                >
                  <XAxis dataKey="month" tick={{ fill: '#900a0a', fontWeight: 'bold' }} />
                  <YAxis hide={true} />
                  <Tooltip />
                  <Bar dataKey="amount" fill="#900a0a" radius={[5, 5, 0, 0]} barSize={50} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CashInCard;
