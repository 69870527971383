import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./context/PrivateRoute";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/auth/login";
import Elements from "./components/auth/elements";
// import Elements1 from "./components/auth/Elements1";

import Dashboard from "./components/pages/dashboard";
import CashInCard from "./components/pages/barchart";
import TableComponent from "./components/pages/table";
import VendorMaster from "./components/pages/vendorMaster";
import CustomDateRangePicker from "./components/pages/test";
import SiteMaster from "./components/pages/siteMaster";
import ReportDetails from "./components/pages/report";
import Layout from "./layout";

const detectPlatform = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    console.log("Opened on Android");
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !navigator.userAgent.includes('Mac')) {
    console.log("Opened on iOS");
  } else if (/windows/i.test(userAgent)) {
    console.log("Opened on Windows");
  } else {
    console.log("Opened on an Unknown Platform");
  }
};


function App() {

 useEffect(() => {
    detectPlatform();
  }, []);
 

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/layout/*" element={<Layout />}>
          
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="barchart" element={<CashInCard />} />
            <Route path="elements" element={<Elements />} />
            <Route path="table" element={<TableComponent />} />
            <Route path="vendor" element={<VendorMaster />} />
            <Route path="test" element={<CustomDateRangePicker />} />
            <Route path="sitemaster" element={<SiteMaster />} />
            <Route path="report" element={<ReportDetails />} />
            
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
