import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
// import './Table.css';
import '../auth/element.css'
import { Button, Col, Dropdown, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFile, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const TableComponent: React.FC = () => {

  const locations = [
    { name: 'Location Name 1', color: 'lightblue' },
    { name: 'Location Name 2', color: 'lightcoral' },
    { name: 'Location Name 3', color: '#b8cde3' },
    { name: 'Location Name 4', color: '#d4d4c0' },
    { name: 'Location Name 5', color: 'lightpink' },
];

const initialData = Array.from({ length: 50 }, (_, index) => {
  const location = locations[Math.floor(Math.random() * locations.length)];
  return {
      id: index + 1,
      siteName: 'Site Name',
      location: location.name,
      color: location.color,
      createdDate: new Date(2023, Math.floor(Math.random() * 12), Math.floor(Math.random() * 28)).toLocaleDateString(),
  };
});

    const [data] = useState(initialData);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    // Calculate pagination
    const pageCount = Math.ceil(data.length / itemsPerPage);
    const offset = currentPage * itemsPerPage;
    const currentData = data.slice(offset, offset + itemsPerPage);

    // Handle page click
    const handlePageClick = (event: { selected: number }) => {
        setCurrentPage(event.selected);
    };

    // Calculate the display range for the items
    const startItem = offset + 1;
    const endItem = Math.min(offset + itemsPerPage, data.length);

    const [filterTemp, setFilterTemp] = useState<{
      startDate: string | null;
      endDate: string | null;
    }>({
      startDate: null,
      endDate: null,
    });

    return (
        <div className="element-container">
            <Row className='pb-2'>
            <Col lg="9" md="9">
  <h4 className="category-title pb-2">
    <FontAwesomeIcon icon={faFile} className="icon" />
    Site Details
  </h4>
</Col>
<Col lg="2" md="2" className="">
        <RangePicker
        value={[
          filterTemp.startDate ? dayjs(filterTemp.startDate) : null,
          filterTemp.endDate ? dayjs(filterTemp.endDate) : null,
        ]}
        onChange={(dates) => {
          setFilterTemp({
            startDate: dates?.[0] ? dates[0].format('YYYY-MM-DD') : null,
            endDate: dates?.[1] ? dates[1].format('YYYY-MM-DD') : null,
          });
        }}
        format="YYYY-MM-DD"
        style={{ width: '100%' }}
      />
        </Col>
       
          <Col lg="1" md="1" className="">
            <Button className='exportbtn' >
              <FontAwesomeIcon icon={faDownload} className="icon" />
              Export
            </Button>
          </Col>
      </Row>
        <div className="table-container">
            <table className="custom-table">
                <thead>
                    <tr>
                    <th><input type="checkbox" /></th>
                        <th>Site Name</th>
                        <th>Location</th>
                        <th>Created Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {currentData.map((item, index) => (
        <tr key={item.id}>
               <td><input type="checkbox" /></td>
                            <td className='boldtd'>{item.siteName}</td>
                            <td>
                        <span className="location-badge" style={{ backgroundColor: item.color }}>
                            {item.location}
                        </span>
                    </td>
                            <td>{item.createdDate}</td>
                            <td>
                            <Dropdown align="end">
                      <Dropdown.Toggle
                        variant="link"
                        id="dropdown-basic"
                        style={{ border: "none", background: "transparent" }}
                      ></Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                         
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination-container">
                <div className="pagination-info">
                    {`${startItem} - ${endItem} of ${data.length}`}
                </div>
                <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active-page'}
                    pageClassName={'page-item'}
                    previousClassName={'prev-next'}
                    nextClassName={'prev-next'}
                />
            </div>
        </div>
        </div>
    );
};

export default TableComponent;
