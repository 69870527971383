import React, { useState } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const CustomDateRangePicker = () => {
  // Define the state with explicit types for startDate and endDate
  const [filterTemp, setFilterTemp] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });

  return (
    <div style={{ padding: '20px', background: '#fff', borderRadius: '8px', maxWidth: '300px' }}>
      <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>Date:</label>
      <RangePicker
        value={[
          filterTemp.startDate ? dayjs(filterTemp.startDate) : null,
          filterTemp.endDate ? dayjs(filterTemp.endDate) : null,
        ]}
        onChange={(dates) => {
          setFilterTemp({
            startDate: dates?.[0] ? dates[0].format('YYYY-MM-DD') : null,
            endDate: dates?.[1] ? dates[1].format('YYYY-MM-DD') : null,
          });
        }}
        format="YYYY-MM-DD"
        style={{ width: '100%' }}
      />
    </div>
  );
};

export default CustomDateRangePicker;
