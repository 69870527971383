import React, { useEffect, useState } from 'react'
import { Button, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import CashInCard from './barchart';
import search from "../../assets/search.svg";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import ReactPaginate from 'react-paginate';
import { faFile, faDownload, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TopNavBar from '../Layout/TopNavBar';
import { showAlert, showError, showSuccess } from '../utills/sweetAlert';
import { callApi } from '../utills/api';
import ReportExcel from './reportexcel';

interface Vendor {
  id: number;
  date: string;
  particular: string;
  amount: string;
  payment_type: string;
  type: string;
  sitename: string;
  vendor_idfk: string;
}

const Dashboard: React.FC = () => {

  const [cashInModel, setCashInShow] = useState(false);

  const addCashShow = () => {
    setState(prevState => ({
      ...prevState,
      date: getTodayDate(),
    }));
    setCashInShow(true)
    setState((prevState) => ({
      ...prevState,
      type: "CashIn",
    }));
  }
  const addCashOutShow = () => {
    setState(prevState => ({
      ...prevState,
      date: getTodayDate(),
    }));
    setCashInShow(true)
    setState((prevState) => ({
      ...prevState,
      type: "CashOut",
    }));
  }
  const cashInClose = () => {
    setCashInShow(false);
    setState((prevState) => ({
      ...prevState,
      type: "",
      amount: "",
      payment_type: "",
      particular: "",
      sitename: "",
      vendor_idfk: "",
    }));
    setState(prevState => ({
      ...prevState,
      date: getTodayDate(),
    }));
  }

  const [state, setState] = useState({
    ReportId: "",
    type: "",
    date: "",
    amount: "",
    payment_type: "",
    particular: "",
    sitename: "",
    vendor_idfk: "",
    SiteOptionselect: [],
    VendorOptionselect: [],
  });
  const [reportDetails, setReportDetails] = useState<any[]>([]);

  useEffect(() => {
    getSiteselect();
    tableReportShow();
  }, []);

  const getSiteselect = () => {
    callApi("GET", "site_select_dropdown.php", "")
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          setState((prevState) => ({
            ...prevState,
            SiteOptionselect: response.data
          }));
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });

    callApi("GET", "vendor_select_dropdown.php", "")
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          setState((prevState) => ({
            ...prevState,
            VendorOptionselect: response.data
          }));
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  }
  const reportOnchange = (event: any) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const [refreshCard, setRefreshCard] = useState(false);

  const handleSubmit = (event: any) => {
    const form = event.target.form;
    if (form.checkValidity() === false) {
      return;
    }
    event.preventDefault();
    if (state.type && state.date && state.amount) {
      let request = {
        type: state.type,
        date: state.date,
        amount: state.amount,
        payment_type: state.payment_type,
        particular: state.particular,
        site_idfk: state.sitename,
        vendor_idfk: state.vendor_idfk
      }
      callApi("POST", "report_insert.php", request)
        .then((res) => res.data)
        .then((response) => {
          if (response) {
            setCashInShow(false);
            setState((prevState) => ({
              ...prevState,
              type: "",
              date: "",
              amount: "",
              payment_type: "",
              particular: "",
              sitename: "",
              vendor_idfk: "",
            }));
            tableReportShow();
            showSuccess("Success!", "Expense Created Successfully!");
            setRefreshCard((prev) => !prev);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  const tableReportShow = () => {
    callApi("GET", "report_dashboard.php", "")
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          setReportDetails(response.reports);
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  }
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };
  const filteredData = reportDetails.filter((item: any) =>
    Object.values(item).some((value) =>
      value != null && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentData = filteredData.slice(offset, offset + itemsPerPage);
  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected);
  };
  const startItem = offset + 1;
  const endItem = Math.min(offset + itemsPerPage, filteredData.length);
  const [filterTemp, setFilterTemp] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });


  useEffect(() => {
    if (filterTemp.startDate && filterTemp.endDate) {
      let req = {
        from_date: filterTemp.startDate,
        to_date: filterTemp.endDate
      };
      callApi("POST", "report_select.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response) {
            setReportDetails(response.data);
          }
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });
    } else {
      tableReportShow();
    }
  }, [filterTemp]);

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  // Set the default date when the component loads
  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      date: getTodayDate(),
    }));
  }, []);

  const [expandedItems, setExpandedItems] = useState<{ [key: number]: boolean }>({});

  // Toggle text expansion for each item
  const toggleText = (id: number) => {
    setExpandedItems((prevExpandedItems) => ({
      ...prevExpandedItems,
      [id]: !prevExpandedItems[id],
    }));
  };

  const [selectedVendors, setSelectedVendors] = useState<Vendor[]>([]); // Array to store selected vendor IDs
  const [selectAll, setSelectAll] = useState(false);

  const isSelected = (item: any) => {
    return selectedVendors.some((vendor) => vendor.id === item.id);
  };

  const handleCheckboxChange = (item: any) => {
    setSelectedVendors((prevSelected) =>
      isSelected(item)
        ? prevSelected.filter((vendor) => vendor.id !== item.id) // Deselect the vendor
        : [...prevSelected, item] // Select the vendor
    );
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedVendors([]);
    } else {
      setSelectedVendors(reportDetails);
    }
    setSelectAll(!selectAll);
  };

  return (

    <>
      <div className="mobile_nav_bar">
        <TopNavBar />
      </div>
      <div className='mblcontent'>
        <Row className='webview'>
          <div className="category-header">
            <Col lg="6" md="6" >
              <h3 className="category-title">
                Dashboard
              </h3>
            </Col>
            <Col lg="6" md="6" className='headbtn'>
              <button className="cashinbtn"
                onClick={addCashShow}>
                + Cash IN
              </button>
              <button className="cashoutbtn"
                onClick={addCashOutShow}>
                - Cash Out
              </button>
              <div className="search-bar">
                <img
                  src={search}
                  alt="search-icon"
                  style={{ width: "22px" }}
                />
                <input type="text" placeholder="Search..."
                  onChange={(e) => handleSearch(e.target.value)} />

              </div>
            </Col>
          </div>
        </Row>
        <Row className='mblview mbldashboard'>
          <div className="category-header">

            <Col lg="8" md="8">
              <h3 className="category-title">
                Dashboard
              </h3>
            </Col>
            <div className='mblcol'>
              <Col lg="1" md="1" className="flexend">
                <button className="cashinbtn"
                  onClick={addCashShow}>
                  + Cash IN
                </button>
              </Col>
              <Col lg="1" md="1" className="flexcenter">
                <button className="cashoutbtn"
                  onClick={addCashOutShow}>
                  - Cash Out
                </button>
              </Col>
            </div>
            <Col lg="2" md="2">
              <div className="search-bar webview">
                <img
                  src={search}
                  alt="search-icon"
                  style={{ width: "22px" }}
                />
                <input type="text" placeholder="Search..." />

              </div>
            </Col>
          </div>
        </Row>
        <br />
        <CashInCard key={refreshCard ? 'refresh1' : 'refresh2'} />
        <div className="element-container webview">
          <Row className='pb-2'>
            <Col lg="10" md="11">
              <h4 className="category-title ">
                <FontAwesomeIcon icon={faFile} className="icon" />
                Recent Transaction
              </h4>
            </Col>
            <Col lg="2" md="1" className="flexend">
              <ReportExcel data={selectedVendors.length > 0 ? selectedVendors : filteredData} />
            </Col>
          </Row>
          {filteredData.length > 0 ? (
            <div className="table-container">
              <table className="custom-table">
                <thead>
                  <tr>
                    <th> <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    /></th>
                    <th>Date</th>
                    <th>Particulars</th>
                    <th>Cash IN</th>
                    <th>Cash Out</th>
                    <th>Payment Mode</th>
                    <th>Site</th>
                    <th>Vendor</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={isSelected(item)}
                          onChange={() => handleCheckboxChange(item)} // Pass the entire vendor object
                        />
                      </td>
                      <td style={{ padding: "20px" }}>{dayjs(item.date).format('DD-MM-YYYY')}</td>
                      <td className='boldtd'>{item.particular}</td>
                      <td className='cashin'>
                        {item.type == "CashIn" &&
                          <>   + {item.amount}</>
                        }
                      </td>
                      <td className='cashout'>
                        {item.type == "CashOut" &&
                          <>   - {item.amount}</>
                        }
                      </td>
                      <td>{item.payment_type}</td>
                      <td>
                        <span style={{ backgroundColor: item.color }}>
                          {item.sitename}
                        </span>
                      </td>
                      <td>
                        {item.vendor_idfk}
                      </td>
                    </tr>
                  ))}

                  {(() => {
                    const cashInTotal = filteredData
                      .filter(item => item.type === "CashIn")
                      .reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);
                    const cashOutTotal = filteredData
                      .filter(item => item.type === "CashOut")
                      .reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);
                    return (
                      <tr className="total-value-row">
                        <td colSpan={2} className="boldtotal"></td>
                        <td className="boldtd">Total Value</td>

                        <td className="boldtotal">
                          + {cashInTotal.toFixed(2)}
                        </td>
                        <td className="boldtotalout">
                          - {cashOutTotal.toFixed(2)}
                        </td>
                        <td colSpan={3}></td>
                      </tr>
                    );
                  })()}
                </tbody>
              </table>
              <div className="pagination-container">
                <div className="pagination-info">
                  {`${startItem} - ${endItem} of ${filteredData.length}`}
                </div>
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  activeClassName={'active-page'}
                  pageClassName={'page-item'}
                  previousClassName={'prev-next'}
                  nextClassName={'prev-next'}
                />
              </div>
            </div>
          ) : (
            <p className='nodata'>Data Not Found</p>
          )}

        </div>

        <div className='mblview' >
          <Row>
            {currentData.map((item, index) => {
              const isExpanded = expandedItems[item.id] || false;
              const truncatedText = item.particular.length > 20 ? item.particular.slice(0, 20) + '....' : item.particular;
              return (
                <Col lg="3" md="3" key={item.id}>
                  <div className="clientcard">
                    <div className="card-content">
                      <div className="client-info">
                        <p className='mblpara fw-normal mblcalicon'>
                          <FontAwesomeIcon icon={faCalendarDays} /> {item.date}
                        </p>
                        <p
                          className='mblpara fw-bold'
                          onClick={() => toggleText(item.id)}
                          style={{ cursor: 'pointer' }}
                        >
                          {isExpanded ? item.particular : truncatedText}
                        </p>
                        <p className='mblpara fw-light'>{item.sitename}</p>
                      </div>
                      <div className="client-image">
                        {item.type == "CashIn" &&
                          <p className='mblcashin fw-bold cashin'>+ {item.amount}</p>
                        }
                        {item.type == "CashOut" &&
                          <p className='mblcashin fw-bold cashout'>- {item.amount}</p>
                        }
                        <p className='mblcashout fw-bold'>{item.payment_type}</p>
                        <p className='mblcashin fw-normal'>{item.vendor_idfk}</p>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
        <br />
        <Row>
        </Row>
      </div>
      <Modal show={cashInModel} onHide={cashInClose}>
        <Modal.Header closeButton>
          {state.type == "CashIn" &&
                          <><h4 className='category-title '>Cash IN Form</h4>
</>
                        }
                           {state.type == "CashOut" &&
                          <><h4 className='category-title '>Cash Out Form</h4>
</>
                        }
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="loginform">

              <div className="labeldiv">
                <label className="label">Date*</label>
                <input
                  type="date"
                  className="form-control bgborder"
                  placeholder="Enter Date"
                  name="date"
                  required
                  onChange={reportOnchange}
                  value={state.date}
                  max={getTodayDate()}
                />
              </div>

              <div className="labeldiv">
                <label className="label">Amount*</label>

                <input
                  type="text"
                  className="form-control bgborder"
                  placeholder="Enter Amount"
                  name="amount"
                  minLength={2}
                  maxLength={100}
                  required
                  onChange={reportOnchange}
                  value={state.amount}
                  onKeyPress={(e) => {
                    const isNumberOrDot = /^[0-9.]$/.test(e.key);
                    const target = e.target as HTMLInputElement; // Cast to HTMLInputElement
                    const hasDecimalPoint = target.value.includes('.');

                    // Check for valid key and prevent entering multiple decimal points
                    if (!isNumberOrDot || (e.key === '.' && hasDecimalPoint)) {
                      e.preventDefault();
                    }
                  }}
                  onPaste={(e) => {
                    const pastedData = e.clipboardData.getData('text');

                    // Prevent pasting anything that is not numbers or a single decimal point
                    if (!/^[0-9.]*$/.test(pastedData)) {
                      e.preventDefault();
                    }
                  }}
                  onInput={(e) => {
                    const target = e.target as HTMLInputElement; // Cast to HTMLInputElement
                    const value = target.value;

                    // Remove any character that is not a number or a single decimal point
                    if (!/^[0-9.]*$/.test(value)) {
                      target.value = value.replace(/[^0-9.]/g, '');
                    }

                    // Ensure only one decimal point is allowed
                    const decimalIndex = value.indexOf('.');
                    if (decimalIndex !== -1 && value.indexOf('.', decimalIndex + 1) !== -1) {
                      target.value = value.substring(0, decimalIndex + 1) + value.substring(decimalIndex + 1).replace(/\./g, '');
                    }
                  }}
                />


                {/* <div className="text-danger">{ExamountError}</div> */}
              </div>

              <div className="labeldiv">
                <label className="label">Payment Type* </label>
                <Form.Select
                  aria-label="Default select example"
                  name="payment_type"
                  required
                  onChange={reportOnchange}
                  value={state.payment_type}
                >
                  <option value="">Select Payment</option>
                  <option value="Credit Card">Credit Card</option>
                  <option value="Debit Card">Debit Card</option>
                  <option value="Cash">Cash</option>
                  <option value="Bank Transfer">Bank Transfer</option>
                  <option value="PayPal">PayPal</option>
                  <option value="UPI">UPI</option>
                  <option value="Gpay">Gpay</option>
                  <option value="Paytm">Paytm</option>
                </Form.Select>
              </div>
              <div className="labeldiv">
                <label className="label">Particular*</label>
                <input
                  type="text"
                  className="form-control bgborder"
                  placeholder="Enter Particular"
                  name="particular"
                  minLength={2}
                  maxLength={50}
                  required
                  onChange={reportOnchange}
                  value={state.particular}
                  pattern="^[A-Za-z0-9@#$_%&-]+( [A-Za-z0-9@#$_%&-]+)*$"
                />
              </div>

              <div className="labeldiv">
                <label className="label">Site* </label>
                <Form.Select
                  aria-label="Default select example"
                  name="sitename"
                  required
                  onChange={reportOnchange}
                  value={state.sitename}

                >
                  <option value="">Select Site</option>
                  {state.SiteOptionselect.map((categery: any) => (
                    <option key={categery.key} value={categery.key}>
                      {categery.label}
                    </option>
                  ))}

                </Form.Select>
              </div>

              <div className="labeldiv">
                <label className="label">Vendor </label>
                <Form.Select
                  aria-label="Default select example"
                  name="vendor_idfk"
                  onChange={reportOnchange}
                  value={state.vendor_idfk}
                >
                  <option value="">Select Vendor</option>
                  {state.VendorOptionselect.map((categery: any) => (
                    <option key={categery.key} value={categery.key}>
                      {categery.label}
                    </option>
                  ))}

                </Form.Select>
              </div>

              <div className="savenew">

                <button
                  className="btn btn-primary modalsubbutton mt-2 mb-2"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Dashboard
