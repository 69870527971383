import React, { useState } from "react";
import "./element.css";
import { Button, CardBody, Col, Form, Modal, Row } from "react-bootstrap";
import search from "../../assets/search.svg";
import CashInCard from "../pages/barchart";
import TableComponent from "../pages/table";



const Elements = () => {
  const [cashInModel, setCashInShow] = useState(false);
  const addCashShow = () => setCashInShow(true);
  
  const cashInClose = () => {
    setCashInShow(false);
  }

  
 
  return (
    <div>
      <div className="">

        <div className="element-container">
                <Row>
                    <div className="category-header">
                  <Col lg="8" md="8">
                      <h3 className="category-title">
                        Dashboard
                      </h3>
                      </Col>
                  <Col lg="1" md="1" className="flexend">
                      <button className="cashinbtn"
                      onClick={addCashShow}>
                        Cash IN
                      </button>
                      </Col>
                      <Col lg="1" md="1" className="flexcenter">
                      <button className="cashoutbtn">
                        Cash Out
                      </button>
                      </Col>
                  <Col lg="2" md="2">
                      <div className="search-bar">
                      <img
                            src={search}
                            alt="search-icon"
                            style={{ width: "22px" }}
                          />
                          <input type="text" placeholder="Search..." />
                         
                        </div>
                        </Col>
                    </div>
                </Row>
              </div>

              <div className="element-container">
              <CashInCard />
              </div>

              <div className="element-container">
              <TableComponent />
              </div>

                 <Modal show={cashInModel} onHide={cashInClose}>
        <Modal.Header closeButton>
          <Modal.Title>Expense Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form className="formalign">
          <div className="loginform">
            <div className="labeldiv">
              <label className="label">Type* </label>
              <Form.Select
                aria-label="Default select example"
                name="expensesite"
                // onChange={handleExpenseChange}
                // onFocus={() => setExsiteError("")}
                // value={expensesite}
              >
                <option value="">Select Type</option>
                <option value="">Cash In</option>
                <option value="">Cash Out</option>
               
              </Form.Select>

              {/* <div className="text-danger">{ExsiteError}</div> */}
            </div>
            <div className="labeldiv">
              <label className="label">Date*</label>
              <input
                type="date"
                className="form-control bgborder"
                placeholder="Enter Date"
                name="expensedata"
                minLength={5}
                maxLength={100}
                required
                // onChange={handleExpenseChange}
                // onFocus={() => setExdateError("")}
                // value={expensedata}
              />
              {/* <div className="text-danger">{ExdateError}</div> */}
            </div>
          
            <div className="labeldiv">
              <label className="label">Amount*</label>
              <input
                type="text"
                className="form-control bgborder"
                placeholder="Enter Amount"
                name="expenseamount"
                minLength={5}
                maxLength={100}
                required
                // onChange={handleExpenseChange}
                // onFocus={() => setExamountError("")}
                // value={expenseamount}
                onKeyPress={(e) => {
                  const isNumber = /[0-9]/.test(e.key);
                  if (!isNumber) {
                    e.preventDefault();
                  }
                }}
              />
              {/* <div className="text-danger">{ExamountError}</div> */}
            </div>

            <div className="labeldiv">
              <label className="label">Payment Type* </label>
              <Form.Select
                aria-label="Default select example"
                name="expensesite"
                // onChange={handleExpenseChange}
                // onFocus={() => setExsiteError("")}
                // value={expensesite}
              >
                <option value="">Select Payment</option>
                <option value="">Cash In</option>
                <option value="">Cash Out</option>
               
              </Form.Select>
              </div>

              <div className="labeldiv">
              <label className="label">Particular*</label>
              <input
                type="text"
                className="form-control bgborder"
                placeholder="Enter Particular"
                name="expensedescription"
                minLength={5}
                maxLength={100}
                required
              />
              </div>

              <div className="labeldiv">
              <label className="label">Site* </label>
              <Form.Select
                aria-label="Default select example"
                name="expensesite"
                // onChange={handleExpenseChange}
                // onFocus={() => setExsiteError("")}
                // value={expensesite}
              >
                <option value="">Select Site</option>
                <option value="">Cash In</option>
                <option value="">Cash Out</option>
               
              </Form.Select>
              </div>
            
              <div className="labeldiv">
              <label className="label">Vendor* </label>
              <Form.Select
                aria-label="Default select example"
                name="expensesite"
                // onChange={handleExpenseChange}
                // onFocus={() => setExsiteError("")}
                // value={expensesite}
              >
                <option value="">Select Vendor</option>
                <option value="">Cash In</option>
                <option value="">Cash Out</option>
               
              </Form.Select>
              </div>
           
            <div className="savenew">
              <button
                className="btn btn-primary modalsubbutton"
                   
              >
                Submit
              </button>
            </div>
          </div>
          </form>
        </Modal.Body>
      </Modal>

      </div>
    </div>
  );
};

export default Elements;
