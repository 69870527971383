import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import dashboardActiveIcon from "../../assets/sidebar/dashboard-black.svg";
import logout from "../../assets/logout.svg";
import logo from "../../assets/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { showAlert } from "../utills/sweetAlert";
import productblack from "../../assets/sidebar/productblack.svg";
import finblack from "../../assets/sidebar/finblack.svg";
import subblack from "../../assets/sidebar/subscriberblack.svg";
import avatarmale from "../../assets/avator.avif";

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [activeMenu, setActiveMenu] = useState(() => {
    return localStorage.getItem("activeMenu") || "Dashboard";
  });
  const [isOpenReport, setIsOpenReport] = useState(false);
  const [activeReportMenu, setActiveReportMenu] = useState(() => {
    return localStorage.getItem("activeReportMenu") || "";
  });
  const [userName, setUsername] = useState("Welcome");

  useEffect(() => {
    const storedUserDetails: any = sessionStorage.getItem('userDetails');
    const userDetails = JSON.parse(storedUserDetails);
    setUsername(userDetails?.userName);
  });

  useEffect(() => {
    localStorage.setItem("activeMenu", activeMenu);
    localStorage.setItem("activeReportMenu", activeReportMenu);
  }, [activeMenu, activeReportMenu]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const toggleReport = () => {
    setIsOpenReport(!isOpenReport);
  };

  const handleMenuClick = async (menu: string) => {
    localStorage.setItem("activeMenu", menu);
    await setActiveMenu(menu);
    if (menu != "Reports") {
      setIsOpenReport(false);
      setActiveReportMenu("");
      localStorage.setItem("activeReportMenu", '');
    }
  };
 

  const storedUserDetails: any = sessionStorage.getItem('userDetails');
  const userDetails = JSON.parse(storedUserDetails);
  const userType = "0";

  const handleLogoutone = async () => {
    const isComfirm = await showAlert("Please Confirm", "Are you sure you want to Logout ?", "Yes", "No")
    if (isComfirm) {
      sessionStorage.removeItem('authkey'); 
        navigate("/");
        localStorage.clear();
        sessionStorage.clear();
    }
  }
  const username = sessionStorage.getItem('useremail');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  return (
    <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
      <div className="sidebar-header">
        <div className="header-content">
          <div className="sidebar-heading">
            <div className="d-flex">
              <img src={logo} alt="Logo" className="profile-logo" />
              {isOpen && <span className="profile-name">{userName}</span>}
            </div>
          </div>
          <div className="sidebar-toggle" onClick={toggleSidebar}>
          </div>
        </div>
      </div>
      <hr className="horizontal-line" />
      <div className="sidebar-header">
      </div>
      <div className="sibebarcontent">
      <ul className="sidebar-menu" style={{ paddingLeft: isOpen ? "5%" : "15%" }}>
        <li
          >
          <NavLink
            className="menu-button"
            to="/layout/dashboard"
            onClick={() => handleMenuClick("Dashboard")}
          >
            <img
              src={activeMenu === "Dashboard" ? dashboardActiveIcon : dashboardActiveIcon}
              alt="dashboard"
              className="menu-icon-dash"
            />{" "}
            {isOpen && <span style={{ color: activeMenu === "Dashboard" ? "#000000" : "#000000", marginLeft: "10px",fontSize:"18px" }}>Dashboard</span>}
          </NavLink>
        </li>
        <li
       >
            <NavLink
              className="menu-button"
              to="/layout/vendor"
              onClick={() => handleMenuClick("Vendor Master")}
            >
              <img
                src={activeMenu === "Vendor Master" ? productblack : productblack}
                alt="Vendor Master"
                    className="menu-icon-dash"
              />{" "}
              {isOpen && <span style={{ color: activeMenu === "Vendor Master" ? "#000000" : "#000000", marginLeft: "10px",fontSize:"18px" }}>Vendor Master</span>}
            </NavLink>
          </li>

          <li
        >
            <NavLink
            className="menu-button"
              to="/layout/sitemaster"
              onClick={() => handleMenuClick("Site Master")}
            >
              <img
                src={activeMenu === "Site Master" ? subblack : subblack}
                alt="Subscribers"
                    className="menu-icon-dash"
              />{" "}
                           {isOpen && <span style={{ color: activeMenu === "Site Master" ? "#000000" : "#000000", marginLeft: "10px",fontSize:"18px" }}>Site Master</span>}
            </NavLink>
          </li>
          <li
        >
            <NavLink
              className="menu-button"
              style={{
                background: activeMenu === "Report" ? "#f2f2f2" : "#fff",
                color: activeMenu === "Report" ? "#000000" : "#595959",
              }}
              to="/layout/report"
              onClick={() => handleMenuClick("Report")}
            >
              <img
                src={activeMenu === "Report" ? finblack : finblack}
                alt="Report"
                    className="menu-icon-dash"
              />{" "}
                          {isOpen && <span style={{ color: activeMenu === "Report" ? "#000000" : "#000000", marginLeft: "10px",fontSize:"18px" }}>Report</span>}

            </NavLink>
          </li>
      </ul>
      </div>
      <footer className="sidebar-footer">
      <ul className="sidebar-menuone">
        <li>
          <button
            className="menu-button"
            style={{
              backgroundColor: activeMenu === "user" ? "#642d97" : "#fff",
              color: activeMenu === "user" ? "#fff" : "black",
              alignItems:"unset"
            }}
            onClick={() => setIsConfirmationModalOpen(true)} // Open confirmation modal on click
          >
            <img src={avatarmale} alt="dashboard" style={{ width: "50px" }} />{" "}
            {isOpen && (
              <span style={{ fontSize: "16px", color: "#444444" }}>
             Admin
              </span>
            )}
          </button>
          {isOpen && (
            <span
              style={{
                fontSize: "16px",
                color: "#444444",
                position: "absolute",
                left: "73px",
                marginTop: "-36px",
              }}
            >
              {username}
            </span>
          )}
        </li>
        <li>
         
          <div>
            <button className="logout-button"
            onClick={handleLogoutone}>
              <img src={logout} alt="Out" className="logouticon" />
              <span className="logout-text">Logout</span>
            </button>
          </div>
        </li>
      </ul>
</footer>
    </div>
  );
};

export default Sidebar;
