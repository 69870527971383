import React, { Component, useState } from "react";
import "./login.css";
import { CardBody, Col, Row } from "react-bootstrap";
import bg1 from "../../assets/background1.avif";
import { Routes, Route, useNavigate } from "react-router-dom";
import { callApi } from "../../components/utills/api";
import { showError, showSuccess } from "../../components/utills/sweetAlert";
import logo from "../../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");

  const onchange = (e:any) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
      setEmailError("");
    } else if (name === "password") {
      setPassword(value);
      setPasswordError("");
    }
  };

  const handleClick = () => {
    console.log("surya");
    
    let emailError = "";
    let passwordError = "";

    if (!email) {
      emailError = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      emailError = "Invalid email format";
    }

    if (!password) {
      passwordError = "Password is required";
    }

    if (emailError || passwordError) {
      setEmailError(emailError);
      setPasswordError(passwordError);
      return;
    }

    let req = {
      email,
      password,
    };

    sessionStorage.setItem("useremail", email);
    callApi("POST", "login.php", req)
      .then((res) => res.data)
      .then((response) => {
        console.log("response",response);
        if (response["success"] === true) {
          sessionStorage.setItem("authkey", response.response.auth_key);
          sessionStorage.setItem("name", response.response.user.firstName);
          showSuccess("Success!", "Login Successfully!");
          setTimeout(() => {
            // navigate("layout/dashboard");
            window.location.href = "layout/dashboard";
          }, 1000);
        } else {
          showError("Failed", "Email & Password are not matched!");
        }
        if (response["success"] === false) {
          showError("Failed", "Email & Password are not matched!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <main>
      <div className="main">
        <div className="container">
          <Row>
            <Col lg="6" md="6" className="text-center bg-blue p-4 colbg webview">
              <img alt="Logo" className="img-fluid"  src={bg1} />
            </Col>
            <Col lg="6" md="6" className="text-center bg-blue p-4 centercol">
              <CardBody className="">
                <div className="loginform">
                <img alt="Logo" className="img-fluid"  src={logo}  style={{width:"100px"}}/>
                  <h3 className="pt-3">Login</h3>
                  <div className="labeldiv">
                    <label className="label webview"> </label>
                    <div className="form-login"  >
                    <input
                      type="email"
                      className="form-control bg"
                      placeholder="Enter Email"
                      name="email"
                      required
                      value={email}
                      onChange={onchange}
                    />
                    </div>
                    <div className="text-danger">{emailError}</div>
                  </div>
                  <div className="labeldiv">
                    <label className="label webview"></label>
                    <div className="form-login"  >
                    <input
                       type={showPassword ? 'text' : 'password'}
                      className="form-control bg"
                      placeholder="Enter Password"
                           id="password"
                      name="password"
                      required
                      value={password}
                      onChange={onchange}
                    />
                                <FontAwesomeIcon
        icon={showPassword ? faEye : faEyeSlash}
        className="icon eyeicon"
        onClick={togglePasswordVisibility}
      />
      </div>
                    <div className="text-danger">{passwordError}</div>
                  </div>
                  <div className="d-grid">
                    <button
                      className="btn btn-primary subbutton"
                      onClick={handleClick}
                      // onClick={handleClickNavigate}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </CardBody>
            </Col>
          </Row>
        </div>
      </div>
    </main>
  );
}
