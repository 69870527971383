import React, { useEffect, useState } from 'react'
import { Button, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap'
import search from "../../assets/search.svg";
import '../auth/element.css';
import { callApi } from '../utills/api';
import { showAlert, showError, showSuccess } from '../utills/sweetAlert';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { faFile, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import TopNavBar from '../Layout/TopNavBar';
import ReportExcel from './reportexcel';

const { RangePicker } = DatePicker;

interface Vendor {
  id: number;
  date: string;
  particular: string;
  amount: string;
  payment_type:string;
  type:string;
  sitename:string;
  vendor_idfk:string;
}


const ReportDetails: React.FC = () => {
  const [cashInModel, setCashInShow] = useState(false);
  const addCashShow = () => {
    setCashInShow(true)
    setState((prevState) => ({
      ...prevState,
      type: "CashIn",
    }));
  }
  const addCashOutShow = () => {
    setCashInShow(true)
    setState((prevState) => ({
      ...prevState,
      type: "CashOut",
    }));
  }
  const cashInClose = () => {
    setCashInShow(false);
    setState((prevState) => ({
      ...prevState,
      type: "",
      amount: "",
      payment_type: "",
      particular: "",
      sitename: "",
      vendor_idfk: "",
    }));
    setState(prevState => ({
      ...prevState,
      date: getTodayDate(),
    }));
  }
  const [state, setState] = useState({
    ReportId:"",
    type: "",
    date: "",
    amount: "",
    payment_type: "",
    particular: "",
    sitename: "",
    vendor_idfk: "",
    SiteOptionselect: [],
    VendorOptionselect: [],
  });
  const [reportDetails, setReportDetails] = useState<any[]>([]);

  useEffect(() => {
    getSiteselect();
    tableReportShow();
  }, []);

  const getSiteselect = () => {
    callApi("GET", "site_select_dropdown.php", "")
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          setState((prevState) => ({
            ...prevState,
            SiteOptionselect: response.data
          }));
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });

    callApi("GET", "vendor_select_dropdown.php", "")
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          setState((prevState) => ({
            ...prevState,
            VendorOptionselect: response.data
          }));
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  }
  const reportOnchange = (event: any) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const handleSubmit = (event: any) => {
    const form = event.target.form;
    if (form.checkValidity() === false) {
      return;
    }
    event.preventDefault();
    if (state.type && state.date && state.amount) {
      let request = {
        type: state.type,
        date: state.date,
        amount: state.amount,
        payment_type: state.payment_type,
        particular: state.particular,
        site_idfk: state.sitename,
        vendor_idfk: state.vendor_idfk
      }

      callApi("POST", "report_insert.php", request)
        .then((res) => res.data)
        .then((response) => {
          if (response) {
            setCashInShow(false);
            setState((prevState) => ({
              ...prevState,
              type: "",
              date: "",
              amount: "",
              payment_type: "",
              particular: "",
              sitename: "",
              vendor_idfk: "",
            }));
            tableReportShow();
            showSuccess("Success!", "Expense Created Successfully!");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const tableReportShow = () => {
    callApi("GET", "report_select.php", "")
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          setReportDetails(response.data);
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  }
  
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  const filteredData = reportDetails.filter((item: any) =>
    Object.values(item).some((value) =>
      value != null && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );
  
  // Calculate pagination for filtered data
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentData = filteredData.slice(offset, offset + itemsPerPage);
  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected);
  };
  
  // Calculate the display range for the items
  const startItem = offset + 1;
  const endItem = Math.min(offset + itemsPerPage, filteredData.length);

  const [filterTemp, setFilterTemp] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    if (filterTemp.startDate && filterTemp.endDate) {
      let req = {
        from_date: filterTemp.startDate,
        to_date: filterTemp.endDate
      };
      callApi("POST", "report_select.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response) {
            setReportDetails(response.data);
          }
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });
    } else {
      tableReportShow();
    }
  }, [filterTemp]);

  const handleEdit = (id: any) => {
    let req = {
      site_id: id,
      };
      callApi("POST", "report_id_select.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
            setState((prevState) => ({
                ...prevState,
                ReportId:response.list[0].id,
                type:response.list[0].type,
                date: response.list[0].date,
                amount: response.list[0].amount,
                payment_type:response.list[0].payment_type,
                particular: response.list[0].particular,
                sitename:response.list[0].site_idfk,
                vendor_idfk:response.list[0].vendor_idfk,
              }));
              setCashInShow(true)
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
}

const handleUpdate = (event: any) => {
  const form = event.target.form;
  event.preventDefault();
  let req = {
  id: state.ReportId,
  type:state.type,
  date:state.date,
  amount:state.amount,
  payment_type:state.payment_type,
  particular:state.particular,
  site_idfk:state.sitename,
  vendor_idfk:state.vendor_idfk,
    };

    callApi("POST", "report_edit.php", req)
    .then((res) => res.data)
    .then((response) => {
      if (response) {
          showSuccess("Success!", "Expense Updated Successfully!");
          setState((prevState) => ({
              ...prevState,
              VendorName: "",
              ProductService:"",
              VendorId:"",
            }));
            setCashInShow(false)
            tableReportShow();
      }
    })
    .catch((err: any) => {
      console.error(
        "API call error:",
        err.response ? err.response.data.error : err.message
      );
      showError(
        "Failed",
        err.response ? err.response.data.error : err.message
      );
    });
}

const handleDelete = async (id: any) => {
  const isComfirm = await showAlert(
    "Please Confirm",
    "Are you sure you want to Delete?",
    "Yes",
    "No"
  );
  if (isComfirm) {
  let req = {
      id: id,
    };
    callApi("POST", "report_delete.php", req)
    .then((res) => res.data)
    .then((response) => {
      if (response) {
    showSuccess("Success!", "Expense Deleted Successfully!");
    tableReportShow();
      }
    })
    .catch((err: any) => {
      console.error(
        "API call error:",
        err.response ? err.response.data.error : err.message
      );
      showError(
        "Failed",
        err.response ? err.response.data.error : err.message
      );
    });
  }
}

const getTodayDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const dd = String(today.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
};

useEffect(() => {
  setState(prevState => ({
    ...prevState,
    date: getTodayDate(),
  }));
}, []);
const [selectedVendors, setSelectedVendors] = useState<Vendor[]>([]); // Array to store selected vendor IDs
const [selectAll, setSelectAll] = useState(false); 
         const isSelected = (item: any) => {
          return selectedVendors.some((vendor) => vendor.id === item.id);
        };   
        const handleCheckboxChange = (item: any) => {
          setSelectedVendors((prevSelected) =>
            isSelected(item)
              ? prevSelected.filter((vendor) => vendor.id !== item.id) // Deselect the vendor
              : [...prevSelected, item] // Select the vendor
          );
        };   
      
        const handleSelectAllChange = () => {
          if (selectAll) {
            setSelectedVendors([]);
          } else {
            setSelectedVendors(reportDetails);
          }
          setSelectAll(!selectAll);
        };

  return (
    <>
    <div>
      <div className="mobile_nav_bar">
        <TopNavBar />
      </div>
      <div className="">
        <Row className='mblview'>
          <div className="category-header">
            <div className='mblcol'>
              <Col lg="8" md="8">
                <h3 className="category-title">
                  Report
                </h3>
              </Col>

              <Col lg="1" md="1" className="">
                <button className="cashinbtn"
                  onClick={addCashShow}>
                  + Cash IN
                </button>
              </Col>
              <Col lg="1" md="1" className="">
                <button className="cashoutbtn"
                  onClick={addCashOutShow}>
                  - Cash Out
                </button>
              </Col>
            </div>
            <Col lg="2" md="2" className='flexcenter'>
              <div className="search-bar">
                <img
                  src={search}
                  alt="search-icon"
                  style={{ width: "22px" }}
                />
                <input type="text" placeholder="Search..."
                  onChange={(e) => handleSearch(e.target.value)} />

              </div>
            </Col>
          </div>
        </Row>
        <Row className='webview'>
          <div className="category-header">
          <Col lg="6" md="6" >
              <h3 className="category-title">
                Report
              </h3>
            </Col>
            <Col lg="6" md="6" className='headbtn'>
              <button className="cashinbtn"
                onClick={addCashShow}>
                + Cash IN
              </button>
              <button className="cashoutbtn" onClick={addCashOutShow}>
                - Cash Out
              </button>
              <div className="search-bar">
                <img
                  src={search}
                  alt="search-icon"
                  style={{ width: "22px" }}

                />
                <input type="text" placeholder="Search..."
                  onChange={(e) => handleSearch(e.target.value)} />

              </div>
            </Col>
          </div>
        </Row>
      </div>

      <div className="element-container">
        <Row className='pb-2'>
        <Col lg="8" md="8">
            <h4 className="category-title pb-2">
              <FontAwesomeIcon icon={faFile} className="icon" />
              Report Details
            </h4>
          </Col>
          <Col lg="4" md="4" className="dateexport">
            <RangePicker
              value={[
                filterTemp.startDate ? dayjs(filterTemp.startDate) : null,
                filterTemp.endDate ? dayjs(filterTemp.endDate) : null,
              ]}
              onChange={(dates) => {
                setFilterTemp({
                  startDate: dates?.[0] ? dates[0].format('YYYY-MM-DD') : null,
                  endDate: dates?.[1] ? dates[1].format('YYYY-MM-DD') : null,
                });
              }}
              format="DD-MM-YYYY"
              style={{ width: '100%' }}
            />

          <ReportExcel data={selectedVendors.length > 0 ? selectedVendors :filteredData} />
          </Col>
        </Row>
        {filteredData.length > 0 ? (
          <div className="table-container">
            <table className="custom-table">
              <thead>
                <tr>
                <th> <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                /></th>
                  <th>Date</th>
                  <th>Particulars</th>
                  <th>Cash IN</th>
                  <th>Cash Out</th>
                  <th>Payment Mode</th>
                  <th>Site</th>
                  <th>Vendor</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {currentData.map((item) => (
                  <tr key={item.id}>
                    <td>
              <input
                type="checkbox"
                checked={isSelected(item)}
                onChange={() => handleCheckboxChange(item)} // Pass the entire vendor object
              />
           </td>
                    <td >{dayjs(item.date).format('DD-MM-YYYY')}</td>
                    <td className='boldtd'>{item.particular}</td>
                    <td className='cashin'>
                      {item.type == "CashIn" &&
                        <>   + {item.amount}</>
                      }
                    </td>
                    <td className='cashout'>
                      {item.type == "CashOut" &&
                        <>   - {item.amount}</>
                      }
                    </td>
                    <td>{item.payment_type}</td>
                    <td>
                      <span style={{ backgroundColor: item.color }}>
                        {item.sitename}
                      </span>
                    </td>
                    <td>
                      {item.vendor_idfk}
                    </td>
                    <td>
                      <Dropdown align="end">
                        <Dropdown.Toggle variant="link" id="dropdown-basic" style={{ border: "none", background: "transparent" }}></Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => handleEdit(item.id)}
                          >Edit</Dropdown.Item>
                          <Dropdown.Item
                          onClick={() => handleDelete(item.id)} >Delete</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}

                {(() => {
                  const cashInTotal = filteredData
                    .filter(item => item.type === "CashIn")
                    .reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);
                  const cashOutTotal = filteredData
                    .filter(item => item.type === "CashOut")
                    .reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);

                  return (
                    <tr className="total-value-row">
                      <td colSpan={2} className="boldtotal"></td>
                      <td className="boldtd">Total Value</td>
                      <td className="boldtotal">
                        {cashInTotal.toFixed(2)}
                      </td>
                      <td className="boldtotalout">
                        {cashOutTotal.toFixed(2)}
                      </td>
                      <td colSpan={3}></td>
                    </tr>
                  );
                })()}

              </tbody>
            </table>
            <div className="pagination-container">
              <div className="pagination-info">
              {`${startItem} - ${endItem} of ${filteredData.length}`}
              </div>
              <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        activeClassName={'active-page'}
        pageClassName={'page-item'}
        previousClassName={'prev-next'}
        nextClassName={'prev-next'}
      />
            </div>
          </div>
        ) : (
          <p className='nodata'>Data Not Found</p>
        )}
      </div>
    </div>
    
      <Modal show={cashInModel} onHide={cashInClose}>
        <Modal.Header closeButton>
        {state.type == "CashIn" &&
                          <><h4 className='category-title '>Cash IN Form</h4>
</>
                        }
                           {state.type == "CashOut" &&
                          <><h4 className='category-title '>Cash Out Form</h4>
</>
                        }
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="loginform">

              <div className="labeldiv">
                <label className="label">Date*</label>
                <input
                  type="date"
                  className="form-control bgborder"
                  placeholder="Enter Date"
                  name="date"
                  required
                  onChange={reportOnchange}
                  value={state.date}
                  max={getTodayDate()}  
                />
              </div>

              <div className="labeldiv">
                <label className="label">Amount*</label>
                <input
                  type="text"
                  className="form-control bgborder"
                  placeholder="Enter Amount"
                  name="amount"
                  minLength={2}
                  maxLength={100}
                  required
                  onChange={reportOnchange}
                  value={state.amount}
                  onKeyPress={(e) => {
                    const target = e.target as HTMLInputElement;
                    const isNumberOrDot = /[0-9.]$/.test(e.key);
                    const hasDecimalPoint = target.value.includes('.');
                  
                    if (!isNumberOrDot || (e.key === '.' && hasDecimalPoint)) {
                      e.preventDefault();
                    }
                  }}
                  
                />
              </div>

              <div className="labeldiv">
                <label className="label">Payment Type* </label>
                <Form.Select
                  aria-label="Default select example"
                  name="payment_type"
                  required
                  onChange={reportOnchange}
                  value={state.payment_type}
                >
                  <option value="">Select Payment</option>
                  <option value="Credit Card">Credit Card</option>
                  <option value="Debit Card">Debit Card</option>
                  <option value="Cash">Cash</option>
                  <option value="Bank Transfer">Bank Transfer</option>
                  <option value="PayPal">PayPal</option>
                  <option value="UPI">UPI</option>
                  <option value="Gpay">Gpay</option>
                  <option value="Paytm">Paytm</option>

                </Form.Select>
              </div>
              <div className="labeldiv">
                <label className="label">Particular*</label>
                <input
                  type="text"
                  className="form-control bgborder"
                  placeholder="Enter Particular"
                  name="particular"
                  minLength={2}
                  maxLength={100}
                  required
                  onChange={reportOnchange}
                  value={state.particular}
                    pattern="^[a-zA-Z0-9]+$"
                />
              </div>
              <div className="labeldiv">
                <label className="label">Site* </label>
                <Form.Select
                  aria-label="Default select example"
                  name="sitename"
                  required
                  onChange={reportOnchange}
                  value={state.sitename}
                >
                  <option value="">Select Site</option>
                  {state.SiteOptionselect.map((categery: any) => (
                    <option key={categery.key} value={categery.key}>
                      {categery.label}
                    </option>
                  ))}
                </Form.Select>
              </div>

              <div className="labeldiv">
                <label className="label">Vendor </label>
                <Form.Select
                  aria-label="Default select example"
                  name="vendor_idfk"
                  onChange={reportOnchange}
                  value={state.vendor_idfk}
                >
                  <option value="">Select Vendor</option>
                  {state.VendorOptionselect.map((categery: any) => (
                    <option key={categery.key} value={categery.key}>
                      {categery.label}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div className="savenew">
              {state.ReportId ? (
                                    <>
                                     <button
                                    className="btn btn-primary modalsubbutton mt-3 mb-3"
                                    type="button"
                                    onClick={handleUpdate}
                                >
                                    Update
                                </button>
                                    </>
                                ):(
                <button
                  className="btn btn-primary modalsubbutton mt-2 mb-2"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
                  )}
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ReportDetails